import type { ISiteApis, IWixAPI } from '@wix/yoshi-flow-editor';

import {
  GROUPS_APP_DEFINITION_ID,
  MEMBERS_APP_DEFINITION_ID,
} from '../constants';

/**
 * Resolve verticals base urls
 * please bear in mind that object keys should be named the same as routes names.
 * router will inject baseUrls to them during registration stage
 */
export async function resolveVerticalsBaseUrls(api: IWixAPI) {
  const [group, groups] = await Promise.all([
    getGroupPageUrl(api.site),
    getGroupListPageUrl(api.site),
  ]);

  return {
    group: removeTrailingSlash(group),
    groups: removeTrailingSlash(groups),
  };
}

function getGroupPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'group',
      appDefinitionId: GROUPS_APP_DEFINITION_ID,
    },
    '/group',
  );
}

function getGroupListPageUrl(site: ISiteApis) {
  return getComponentUrl(
    site,
    {
      sectionId: 'groups',
      appDefinitionId: GROUPS_APP_DEFINITION_ID,
    },
    '/groups',
  );
}

async function getComponentUrl(
  site: ISiteApis,
  sectionIdentifier: {
    appDefinitionId: string;
    sectionId: 'groups' | 'group' | 'membership_plan_picker_tpa' | 'about';
  },
  fallback: string,
) {
  try {
    const { pages } = await site.getSiteStructure({
      includePageId: true,
    } as any);

    const [page] = pages.filter(
      (page: any) =>
        page.tpaPageId === sectionIdentifier.sectionId &&
        page.applicationId === sectionIdentifier.appDefinitionId,
    );

    if (!page) {
      return fallback;
    }

    return page.isHomePage ? '/' : page.url || fallback;
  } catch {
    return fallback;
  }
}

function removeTrailingSlash(url: string) {
  return url.replace(/(\/)\/+/g, '/');
}
